<template>
  <div class="footer-wrap">
    <div>
      <div class="footer-con container">
        <div class="login-wrap">
          <div>
            <router-link class="svg-box w-100 h-100 logo" to="/musicFine">
              <img src="@/assets/images/web/home/logo.png" alt="logo" style="width: 8.7rem;height: 3.9rem;" />
            </router-link>
          </div>
          <div class="logo2"></div>
        </div>
        <div class="footer-content">
          <div class="serve-wrap">
            <span class="color-ff45">帮助中心</span>
            <router-link :to="`/helpCenter?tab=musician`">音乐人</router-link>
            <router-link :to="`/helpCenter?tab=kehu`">客户</router-link>
            <router-link class="hidden-sm" to="/authCenter">授权查询</router-link>
          </div>

          <div class="serve-wrap">
            <span class="color-ff45">服务协议</span>
            <router-link to="/termsOfService">SparkMusic服务协议</router-link>
            <router-link to="/termsOfServiceVip">VIP会员服务协议</router-link>
          </div>

          <div class="serve-wrap">
            <span class="color-ff45">关于我们</span>
            <router-link to="/aboutUs">关于我们</router-link>
            <router-link to="/aboutUs?anchor=contactUs">联系我们</router-link>
          </div>

          <div class="serve-wrap border-rigrht-none">
            <span class="color-ff45">友情链接</span>
            <div class="flex-1 serve-list">
              <a href="https://www.youxibbs.cn" target="_blank">游戏论坛</a>
              <a href="http://youxiputao.com" target="_blank">游戏葡萄</a>
              <a href="http://www.51gpc.com" target="_blank">影视公司</a>
              <!-- <a href="http://www.linekong.com" target="_blank">蓝港互动</a> -->
              <a href="https://www.paixin.com/" target="_blank">正版图片</a>
              <!-- <a href="https://shipin520.com/" target="_blank">视频素材</a> -->
            </div>
          </div>

          <div class="QR-code">
            <div class="QR-item flex flex-column cen-center">
              <div class="code">
                <ng-image class="w-100 h-100" src="/images/web/wechat1.png"></ng-image>
              </div>
              <div>官方公众号</div>
            </div>
            <div class="QR-item flex flex-column cen-center">
              <div class="code">
                <ng-image class="w-100 h-100" src="/images/web/wechat2.png"></ng-image>
              </div>
              <div>微信客服</div>
            </div>
          </div>
        </div>
      </div>

      <div class="bei-an flex row cen-center">
        <span>北京火星火翎文化科技有限公司 | Copyright © 2020-2022. All Rights Reserved |<a href= "https://beian.miit.gov.cn/" rel="noreferrer" target="_blank" style="color: gray;">备案号：京ICP备2020045875号</a> 
          <span><img sytle="width:12px;height:13px;" src="/images/web/icon2.png"></span>
          <a href= "https://beian.mps.gov.cn/#/query/webSearch?code=11010502053869\" rel="noreferrer" target="_blank" style="color: gray;">京公网安备11010502053869</a >
             | 京网文（2021）0831-227号</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        icp:"京ICP备2020045875号"
      };
      
    },
    components: {
      
    },
    mounted() {
      console.log(location.href);
      // if (this.$route.query.from == 4) {
      //   this.icp = "京ICP备2020045875号-5";
      // }
    },
    methods: {
      
      handleServe(path, tab) {

        if (path == "contactUs") {
          // this.$store.commit("changeContactUsDialog", true);

          return;
        }
        console.log("path: ", path);
        this.$router.push({path, query: {tab}});
      },
    },
  };
</script>

<style lang="scss" scoped>
  .footer-wrap {
    width: 100%;
    background: #15110F;
    .footer-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);
      .login-wrap {
        .logo1 {
          width: 8.7rem;
          height: 3.9rem;
          //background-image: url("@/assets/images/web/home/logo.png");
          background-size: 100% 100%;
        }
        .logo2 {
          width: 7.1rem;
          height: 3.1rem;
          margin-top: 1.2rem;
          background-image: url("/images/web/footer-logo2.png");
          background-size: 100% 100%;
        }
      }
      .footer-content {
        display: flex;
        padding: 3.2rem 0 2.4rem;

        .serve-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 2rem;
          border-right: 1px solid rgba(255, 255, 255, 0.12);
          color: rgba(255, 255, 255, 0.85);
          font-size: 1.2rem;
          // font-size: 1.4rem;
          .color-ff45 {
            font-size: 1.4rem;

            color: rgba(255, 255, 255, 0.45);
          }

          a {
            color: rgba(255, 255, 255, 0.85);
            margin-bottom: 0.4rem;
          }

          span {
            margin-bottom: 0.4rem;
            cursor: pointer;

            &:last-of-type {
              margin-bottom: 0;
            }
            &:first-of-type {
              cursor: default !important;
            }
          }
        }
        .border-rigrht-none {
          border: none !important;
        }
        .QR-code {
          display: flex;
          margin-left: 3.3rem;
          .QR-item {
            color: rgba(255, 255, 255, 0.85);
            margin-right: 2rem;
            &:last-of-type {
              margin-right: 0;
            }
            .code {
              width: 7rem;
              height: 7rem;
              background-color: #fff;
              margin-bottom: 0.4rem;
            }
          }
        }
      }
    }
    .bei-an {
      color: rgba(255, 255, 255, 0.45);
      font-size: 1.2rem;
      padding-top: 1.1rem;
      padding-bottom: 1.6rem;
    }
  }

  // mobile
  @media screen and (max-width: $screen-width-md) {
    .footer-wrap {
      width: 100%;
      // height: 21.7rem;
      background: rgba(0, 0, 0, 0.85);
      padding: 3.1rem 1rem 0rem 1rem;
      .container {
        margin: 0;
      }
      .footer-con {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid rgba(255, 255, 255, 0.06);

        .footer-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 2.4rem 0;

          .serve-wrap {
            padding: 0;
            border-right: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            color: rgba(255, 255, 255, 0.85);
            font-size: 1.4rem;
            .color-ff45 {
              color: rgba(255, 255, 255, 0.45);
            }
            .mr12 {
              margin-right: 1.2rem;
            }

            a {
              margin-bottom: 1.6rem;
              margin-right: 1.2rem;
            }

            span {
              margin-bottom: 1.6rem;
              margin-right: 1.2rem;
              cursor: pointer;

              &:last-of-type {
                margin-bottom: 0;
              }
              &:first-of-type {
                cursor: default !important;
                padding-right: 1.2rem;
                flex-shrink: 0;
                border-right: 1px solid rgba(255, 255, 255, 0.12);
              }
            }

            .serve-list {
              flex: 1;
            }
          }
          .border-rigrht-none {
            border: none !important;
          }
          .QR-code {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-left: 0;
            margin-top: 3.2rem;
            .QR-item {
              margin-right: 4rem;
            }
          }
        }
      }
      .bei-an {
        padding-top: 1.1rem;
        padding-bottom: 1.6rem;
      }
    }
  }

  // pc
  @media screen and (max-width: $screen-width-lg) and (min-width: $screen-width-md) {
    .serve-wrap {
      padding: 0 5px !important;
    }
  }

  // pc
  @media screen and (min-width: $screen-width-md) {
    .serve-list {
      display: flex;
      flex-direction: column;
    }
  }
</style>
