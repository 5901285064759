<template>
  <div>
    <ng-dialog
      class="dialog-shop-cart"
      :visible.sync="show"
      :maskClosable="false"
      :bodyStyle="bodyStyle"
      @close="onClose"
      :footer="null"
    >
      <template v-slot:title>
        <div class="header flex">
          <div v-if="data.library_type==0||data.op_id==1"><span style="margin-left:20px;font-size: 14px;font-weight: 500;color: #333333;">下载提示</span></div>
        </div>
        <div  class="header flex" v-if="data.library_type==1&&data.op_download==-1">
          <div style="width:100%;text-align:center;color: #333333;margin-top:24px;">
            <p style="font-size: 18px;font-weight: 500;">开通【订阅会员】免费下载会员专区作品</p>
            <p style="font-size: 14px;font-weight: 400;">正版音乐 每日更新</p>
          </div>
        </div>
      </template>

      <div v-if="data.library_type==0">
        <div v-if="isHigh" class="m-bottom-md">
          <p>尊敬的用户，您享有无损音乐下载权限，如确定使用该作品，请及时完善授权信息。</p>
          <a-button v-if="isActive" type="primary" size="small" @click="onActive">现在激活</a-button>
        </div>
        <div v-else class="m-bottom-md">
          <p>您将下载的是带水印的低音质试用音频，该版本仅供试用 是否继续下载？</p>
        </div>
      </div>
      <div v-else>
        <div v-if="isHigh" class="m-bottom-md">
          <p>尊敬的用户，您享有无损音乐下载权限，如确定使用该作品，请及时完善授权信息。</p>
          <a-button v-if="isActive" type="primary" size="small" @click="onActive">现在激活</a-button>
        </div>
         <div class="m-bottom-md"  v-else-if="data.op_id==1">
          <p>您将下载的是带水印的低音质试用音频，该版本仅供试用完成授权认证后即可下载高清音乐。</p>
        </div>
        <div class="m-bottom-md" v-if="data.op_download==-1">
          <a-row style="text-align:center;color:#333333;vertical-align: top;">
            <a-col :span="6">
              <img src="@/assets/images/web/download/download.png" />
              <p style="margin-top:13px;">甄选订阅曲库</p>
              <p>任意下载</p>
            </a-col>
            <a-col :span="6">
              <img src="@/assets/images/web/download/auth.png" />
              <p style="margin-top:13px;">企业商用授权</p>
              <p>版权无忧 </p>
            </a-col>
            <a-col :span="6">
              <img src="@/assets/images/web/download/copyright.png" />
              <p style="margin-top:13px;">正版授权书</p>
              <p>在线下载</p>
            </a-col>
            <a-col :span="6">
              <img src="@/assets/images/web/download/kefu.png" />
              <p style="margin-top:15px;">专属客服</p>
              <p>1对1</p>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="f-r" style="float: right;">
        <div v-if="data.library_type==0">
          <a-button class="m-right-sm" @click="downloadTryMusic(data)" style="border-radius: 4px;border: 1px solid #FF7B13;font-weight: 400;color: #FF7B13;">下载小样</a-button>
          <a-button v-if="isHigh" @click="donwloadHighMusic(data)" style="border-radius: 4px;border: 1px solid #FF7B13;font-weight: 400;color: #FF7B13;">下载无损音乐</a-button>
        </div>
        <div v-else>
          <a-button v-if="data.library_type==1&&data.op_download==-1" class="m-right-sm" @click="kaitong()" style="border-radius: 4px;border: 1px solid #FF7B13;font-weight: 400;background: #FF7B13;color: #F9F9F9;">开通会员</a-button>
          <a-button v-if="data.library_type==1&&data.op_id==1" class="m-right-sm" @click="renzheng()" style="border-radius: 4px;border: 1px solid #FF7B13;font-weight: 400;background: #FF7B13;color: #F9F9F9;">前往认证</a-button>
          <a-button class="m-right-sm" @click="downloadTryMusic(data)" style="border-radius: 4px;border: 1px solid #FF7B13;font-weight: 400;color: #FF7B13;">下载小样</a-button>
          <a-button v-if="isHigh" @click="donwloadHighMusic(data)" style="border-radius: 4px;border: 1px solid #FF7B13;font-weight: 400;color: #FF7B13;">下载无损音乐</a-button>

        </div>
      </div>
    </ng-dialog>

    <dialog-active-auth-package
      v-if="$store.state.login"
      :visible.sync="showAuthPackageDialog"
      :data="handData"
      @success="onSucces"
    ></dialog-active-auth-package>
  </div>
</template>

<script>
  import NgDialog from "@/components/NgDialog.vue";
  import DialogActiveAuthPackage from '@/pages/components/order/DialogActiveAuthPackage.vue'
  import MusicPlayIcon from "@/pages/components/music/MusicPlayIcon.vue";
  import {dialogMixins} from "@/mixins/index";
  import musicHandle from '@/mixins/musicHandle';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        showAuthPackageDialog: false,
        handData: {},
      };
    },
    
    components: {
      NgDialog,
      MusicPlayIcon,
      DialogActiveAuthPackage
    },

    computed: {
      isHigh() { //可下载
        return this.data && this.data.op_download == this.$val.CommonCheck.correct;
      },
      isActive() { //可授权
        return this.data && this.data.op_auth == this.$val.CommonCheck.correct;
      },
    },

    props: {
      data: Object
    },

    mixins: [dialogMixins, musicHandle],

    methods: {
      showCartDialog(bool) {
        this.$store.commit("changeMusicDownloadDialog", bool);
      },

      onClose() {
        this.showCartDialog(false)
      },

      onActive() {
        this.showAuthPackageDialog = true;
        this.handData = this.data;
      },

      onSucces() {
        this.showCartDialog(false)
      },
      kaitong(){
        this.showCartDialog(false)
        this.$router.replace('/subscribe_introduce_v2');
      },
      renzheng(){
        this.showCartDialog(false)
        this.$router.replace('/member_v2?menukey=3');
      },
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
      .ant-modal-header {
        padding: 0 !important;
        position: initial !important;
        border-bottom:1px solid #e8e8e8 !important;
      }
      .ant-modal-close{
        right: 0 !important;
      }
      .ant-modal-body{
        padding:12px;
      }
      .ant-form-item{
        margin-bottom:5px;
      }
  }
  .dialog-shop-cart {
    .header {
      .play-icon {
        width: 32px;
        height: 32px;
        margin-top: 6px;
      }
    }
  }
</style>
