<template>
  <ng-drawer :visible.sync="showDrawer" :duration="0" :show-close="false">
    <div class="ng-video">
      <audio-custome-mobile v-if="$root.isMobile">
        <template slot="right" slot-scope="data">
          <div class="music-operation">
            <music-operation :video-info="data.slotScope" :showText="true"></music-operation>
          </div>
        </template>
      </audio-custome-mobile>

      <audio-custome-pc v-else>
        <template slot="right" slot-scope="data">
          <div class="music-operation">
            <music-operation :video-info="data.slotScope" :showText="true" :showHistory ='true'></music-operation>
          </div>
        </template>
      </audio-custome-pc>
    </div>
  </ng-drawer>
</template>

<script>
  import {NgDrawer} from '@/components/index'
  import AudioCustomePc from './AudioCustomePc.vue';
  import AudioCustomeMobile from './AudioCustomeMobile.vue'
  import MusicOperation from '@/pages/components/music/MusicOperation.vue'

  export default {
    data() {
      return {
        showDrawer: false,
      }
    },

    components: {
      NgDrawer, AudioCustomePc, AudioCustomeMobile, MusicOperation
    },

    computed: {
      globalAudioInfo() {
        return this.$store.state.globalVideoInfo
      }
    },

    watch: {
      globalAudioInfo: {
        handler(newVal) {
          if (newVal && newVal.url) {
            this.showDrawer = true;
          }
        },
        immediate: true
      }
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-video {
    // color: #fff;
    background-color: #fff;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $border-color;
    }

    .music-operation {
      width: 220px;
      flex-shrink: 0;
    }

    /deep/ {
      .ng-video-content {
        height: 76px;
      }

      .icon-item-box {
        flex-direction: column;

        .text {
          font-size: 12px;
          margin-left: 0 !important;
        }
      }
    }

    @media screen and (max-width: $screen-width-md) {
      /deep/ {
        .ng-video-content {
          height: auto;
        }

        .music-operation {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
</style>
