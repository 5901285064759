<template>
  <div class="ng-video-content">
    <div class="audio-info hidden-sm" style="width: 200px;">
      <div class="logo-image-box">
        <ng-image class="m-right-sm logo-images" :src="videoInfo.logo_url"></ng-image>
      </div>

      <div class="music-info">
        <router-link
          class="font-color-base block music-info-item w-100"
          :to="`/musicDetail/${videoInfo.music_id}`"
        >
          <ng-notice
            class="w-100 name font-bold m-bottom-xs"
            :title="videoInfo.music_name ? videoInfo.music_name : videoInfo.name"
          ></ng-notice>
        </router-link>

        <router-link
          class="font-color-base block music-info-item"
          :to="`/supplierInfo/${videoInfo.suplr_id || '-1'}`"
        >{{videoInfo.suplr_name}}</router-link>
      </div>
    </div>

    <div class="music-handle-box" style="width: 200px;">
      <a-button class="prev-btn btn-item" type="link" :disabled="disabledPrev" @click="goPrevMusic">
        <icon-font type="iconprev"></icon-font>
      </a-button>

      <a-button v-if="!newPlaying" class="play-btn btn-item" type="link" @click="playVideo">
        <icon-font type="iconplay"></icon-font>
      </a-button>
      <a-button v-else class="play-btn btn-item" type="link" @click="pauseVideo">
        <icon-font type="iconpause"></icon-font>
      </a-button>

      <a-button class="next-btn btn-item" type="link" :disabled="disabledNext" @click="goNextMusic">
        <icon-font type="iconnext"></icon-font>
      </a-button>
    </div>

    <div class="flex flex-column music-wave-form hidden-sm flex-1">
      <div class="music-wave-box m-bottom-xs">
        <music-wave
          class="w-100"
          :music_id="videoInfo.music_id || ''"
          :percent="curTime / duration"
          @move="onMoveProgress"
        ></music-wave>
      </div>

      <div class="audio-time flex cen-space w-100">
        <div class="m-right-sm">
          <span>{{curTime | formatTimeBySecond}}</span>
          <span>/</span>
          <span>{{(duration || 0) | formatTimeBySecond}}</span>
        </div>
        <div class="flex cen-space">
          <div class="flex cen-start music-sound-box m-right-md">
            <icon-font v-if="!isMute" type="iconsound" @click="startMute"></icon-font>
            <icon-font v-else type="iconsilence" @click="endMute"></icon-font>

            <div class="flex-1">
              <a-slider v-model="curVolume" :max="1" :step="0.01" :tooltipVisible="false" />
            </div>
          </div>

          <div v-if="videoInfo.child_num > 0" class="music-version hidden-sm" style="margin-left: 36px">
            <music-version :item="videoInfo"></music-version>
          </div>
        </div>
      </div>
    </div>

    <slot name="right" :slot-scope="videoInfo"></slot>
  </div>
</template>

<script>
  import AudioCustome_mixins from './AudioCustome_mixins'

  export default {
    mixins: [AudioCustome_mixins],
  }
</script>

<style lang="scss" scoped>
  $video-box-height: 76px;

  .ng-video-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $video-box-height;
    padding: 0 40px;
    .audio-info {
      display: flex;

      .music-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120px;
        margin-left: 12px;
      }

      .logo-image-box {
        width: 60px;
        height: 60px;

        .logo-images {
          width: 100%;
          height: 100%;
        }
      }
    }

    .music-handle-box {
      display: flex;
      align-items: center;

      .btn-item {
        font-size: 20px;
        color: $font-base-color;

        &[disabled] {
          color: $font-disabled-color;
        }
      }

      .play-btn {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 35px;
        border: 2px solid $font-sub-color;
        border-radius: 100%;
        padding: 0;
      }
    }

    .music-wave-form {
      width: 300px;
      padding: 0 40px 0 24px;

      .music-wave-box {
        height: 20px;
      }

      .music-sound-box {
        width: 150px;
      }

      ::v-deep {
        .ant-slider {
          margin-top: 0;
          margin-bottom: 0;
        }

        .anticon {
          font-size: 18px;
        }
      }
    }
  }
</style>
