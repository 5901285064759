<template>
  <div class="container ng-video-content">
    <div class="mobile-top">
      <div class="left">
        <span>{{videoInfo.music_name ? videoInfo.music_name : videoInfo.name}}</span>
        <span>-</span>
        <span>{{videoInfo.suplr_name}}</span>
      </div>
      <div class="right cursor-pointer" @click="onClickPrev">
        <icon-font :class="[isOpenMobile ? 'active' : '', 'iconangle']" type="iconangle-bottom"></icon-font>
      </div>

      <div class="mobile-progress hidden-md">
        <a-slider
          :value="curTime / duration"
          class="mobile-slider"
          :max="1"
          :min="0"
          :step="0.0001"
          :tooltipVisible="false"
        />

        <div class="time-progress">
          <span>{{curTime | formatTimeBySecond}}</span>
          <span>/</span>
          <span>{{(duration || 0) | formatTimeBySecond}}</span>
        </div>
      </div>
    </div>

    <div :class="['mobile-content', isOpenMobile ? 'active' : '']">
      <div class="music-handle-box">
        <a-button
          class="prev-btn btn-item"
          type="link"
          :disabled="disabledPrev"
          @click="goPrevMusic"
        >
          <icon-font type="iconprev"></icon-font>
        </a-button>

        <a-button v-if="!newPlaying" class="play-btn btn-item" type="link" @click="playVideo">
          <icon-font type="iconplay"></icon-font>
        </a-button>
        <a-button v-else class="play-btn btn-item" type="link" @click="pauseVideo">
          <!-- <icon-font class="rotate" v-if="loading" type="iconload"></icon-font> -->
          <a-icon v-if="loading" type="loading" />
          <icon-font v-else type="iconpause"></icon-font>
        </a-button>

        <a-button
          class="next-btn btn-item"
          type="link"
          :disabled="disabledNext"
          @click="goNextMusic"
        >
          <icon-font type="iconnext"></icon-font>
        </a-button>
      </div>

      <slot name="right" :slot-scope="videoInfo"></slot>
    </div>
  </div>
</template>

<script>
  import AudioCustome_mixins from './AudioCustome_mixins'

  export default {
    data() {
      return {
        isOpenMobile: false,
      }
    },


    mixins: [AudioCustome_mixins],

    methods: {
      onClickPrev() {
        this.isOpenMobile = !this.isOpenMobile;
      }
    }
  }
</script>

<style lang="scss" scoped>
  $video-box-height: 48px;

  ::v-deep {
    .ant-slider-track {
      background-color: $primary-color;
    }

    .ant-slider-handle {
      border: none;
    }
  }

  .ng-video-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: $video-box-height;

    .mobile-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $video-box-height;
      width: 100%;
      border-bottom: 1px solid $border-color;

      .mobile-progress {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .mobile-slider {
          position: absolute;
          top: -6px;
          left: -3%;
          right: -3%;
          margin-top: 0;
        }
      }
    }

    .mobile-content {
      width: 100%;
      height: 0px;
      overflow: hidden;
      transition: all linear 0.2s;

      &.active {
        height: 200px;
      }
    }

    .iconangle {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      transform: rotate(0);
      transition: all linear 0.2s;
      font-size: 20px;

      &.active {
        transform: rotate(180deg);
      }
    }

    .time-progress {
      position: absolute;
      top: -28px;
      height: 26px;
      left: 0;
      color: #fff;
      line-height: 26px;
      padding: 0 10px;
      background-color: $primary-color;
    }

    .music-handle-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
      margin-bottom: 26px;

      .btn-item {
        font-size: 20px;
        color: $font-base-color;

        &[disabled] {
          color: $font-disabled-color;
        }
      }

      .play-btn {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 35px;
        border: 2px solid $font-sub-color;
        border-radius: 100%;
        padding: 0;
      }
    }

    ::v-deep {
      .music-opt {
        display: flex;

        .music-opt-item {
          flex: 1;
        }
      }

      .ant-slider {
        margin-top: 0;
        margin-bottom: 0;
      }

      .anticon {
        font-size: 18px;
      }
    }
  }
</style>
