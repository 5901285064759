<template>
  <div class="tool-wrap">
    <span @click="toTop">
      <icon-font class="iconfont" type="iconupload"></icon-font>
      <span class="h-text">返回顶部</span>
    </span>
    <span>
      <AIcon class="iconfont" type="team"> </AIcon>
      <div class="qrcode">
        <div class="img">
          <img src="@/assets/images/qrCode2.png" alt="" />
          <div class="corner corner0"></div>
          <div class="corner corner1"></div>
          <div class="corner corner2"></div>
          <div class="corner corner3"></div>
        </div>
        <p>官方微信群</p>
      </div>
            <span class="h-text">加入社群</span>

    </span>
    <span @click="Chatra">
      <icon-font class="iconfont" type="iconheadset"></icon-font>
      <span class="h-text">联系客服</span>
    </span>
    <span @click="toHlepCVenter">
      <icon-font
        class="iconfont help-icon"
        type="iconbangzhuzhongxin1"
      ></icon-font>
      <span class="h-text">帮助中心</span>
    </span>
  </div>
</template>

<script>
import { Icon as AIcon } from "ant-design-vue";

export default {
  data() {
    return {};
  },
  components: { AIcon },
  created() {
    let vm = this;
    window.onscroll = function () {
      if (document.documentElement.scrollTop > 60) {
        vm.isActive = true;
      } else {
        vm.isActive = false;
      }
    };
  },
  methods: {
    toTopAimate() {
      // 动画
      let timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },

    toTop() {
      console.log("toTop");
      this.toTopAimate();
    },
    Chatra() {
      window.Chatra("openChat", true);
    },
    toHlepCVenter() {
      this.$router.push({
        path: "/helpCenter",
        query: {
          tab: "kehu",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tool-wrap {
  display: flex;
  flex-direction: column;
  .iconfont {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
  .iconfont1 {
    font-size: 24px;
    width: 24px;
    height: 24px;
    position: relative;
  }
  .qrcode {
    position: absolute;
    width: 230px;
    height: 180px;
    padding: 20px 30px;
    display: none;
    background: linear-gradient(to right, #e938c9, #ee6725);
    right: 40px;
    border-radius: 25px;
    text-align: center;
    .img {
      width: 70%;
      margin-left: 15%;
      position: relative;
      .corner{
        width: 20px;
        height: 20px;
        background: transparent;
        position: absolute;
      }
      .corner0{
        left: -6px;
        top: -6px;
        border-left: 2px solid #fff;
        border-top: 2px solid #fff;
      }
      .corner1{

        right: -6px;
        top: -6px;
        border-right: 2px solid #fff;
        border-top: 2px solid #fff;
      }
      .corner2{
        right: -6px;
        bottom: -6px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      .corner3{

        left: -6px;
        bottom: -6px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      img{
        width: 100%;
      }
      // height: 100%;
    }
    p {
      color: white;
      font-weight: 700;
      margin-top: 10px;
    }
  }
  // .help-icon{
  //   font-size: px;
  //   width: 24px;
  //   height: 24px;
  // }
  span {
    // background-color: #fff;
    cursor: pointer;
    width: 48px;
    height: 40px;
    display: flex;
    padding-left: 7px;
    justify-content: center;
    align-items: center;
    &:hover {
      .iconfont {
        display: none;
      }
      .h-text {
        display: inline-block;
      }
      .qrcode {
        display: block;
      }
    }
    .h-text {
      display: none;
      font-size: 14px;
    }
  }
}
</style>
