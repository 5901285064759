<template>
  <ng-dialog
    class="login-dialog"
    :visible="show"
    :maskClosable="false"
    :footer="null"
    @close="onClose"
  >
    <template v-if="pageType == 'login'">
      <div class="logon-tabs-wrap flex row cen-center">
        <a-tabs v-model="activeKey" :animated="false">
          <a-tab-pane v-for="tab in tabList" :key="tab.id" :tab="tab.tab"></a-tab-pane>
        </a-tabs>
      </div>
      <Login
        @clickRegister="pageType = 'regsirer'"
        @clickResetPwd="pageType = 'resetPwd'"
        :activeKey="activeKey"
      ></Login>
    </template>
    <template v-if="pageType == 'regsirer'">
      <Register @clickLogin="pageType = 'login'" @success="onSuccess"></Register>
    </template>
    <template v-if="pageType == 'resetPwd'">
      <FindPwd @success="onSuccess"></FindPwd>
    </template>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue'
  import Login from "@/pages/components/login/Login.vue";
  import FindPwd from "@/pages/components/login/FindPwd.vue";
  import Register from "@/pages/components/login/Register.vue";

  import {dialogMixins} from "@/mixins/index";
  import {mapMutations} from "vuex";

  export default {
    data() {
      return {
        tabList: [
          {
            id: 1,
            tab: "账号密码登录",
          },
          {
            id: 2,
            tab: "动态密码登录",
          },
        ],
        pageType: "login",
        needPageType:"login",
        activeKey: 1,
        show: false,
      };
    },

    watch: {
      show: {
        handler(newVal) {
          if (newVal) {
            // this.pageType = "regsirer";
            console.log(this.needPageType)
            this.pageType = this.needPageType;
            this.needPageType = "login";
          }
        },
        immediate: true
      }
    },

    components: {
      Login,
      Register,
      FindPwd,
      NgDialog,
    },

    props: {
      data: Object,
    },

    mixins: [dialogMixins],

    methods: {
      ...mapMutations(["changeLoginAndRegisterDialog"]),

      clickRegister() {
        this.pageType = "regsirer";
      },
      needShowRegister(){
        this.needPageType = "regsirer";
      },
      showLoginDialog(bool) {
        this.changeLoginAndRegisterDialog(bool);
      },

      onClose() {
        this.showLoginDialog(false);
      },

      onSuccess() {
        this.pageType = 'login';
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
  .login-dialog {
    .ant-modal-body {
      padding: 60px 95px !important;
    }

    .ant-drawer-body-inner {
      padding-top: 60px !important;
    }

    .logon-tabs-wrap {
      .ant-tabs-bar {
        margin-bottom: 64px;
      }

      .ant-tabs-tab {
        font-size: 18px !important;
        color: rgba(0, 0, 0, 0.55);
      }

      .ant-tabs-tab-active {
        font-size: 24px !important;
        color: rgba(0, 0, 0, 0.85);
      }

      .ant-tabs-ink-bar {
        background-color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }
</style>
