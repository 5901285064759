<template>
  <a-dropdown class="f-l font-grey" placement="topCenter" @visibleChange="onChangeVisible">
    <span
      class="version-num ant-dropdown-link cursor-pointer"
      :class="{open: isOpen}"
      @click="e => e.preventDefault()"
    >
      <span class="m-right-sm">版本数 {{item.child_num}}</span>

      <icon-font class="bottom-arrow" type="iconangle-top-copy"></icon-font>
    </span>

    <a-menu slot="overlay">
      <template v-if="list && list.length > 0">
        <template v-for="(item, index) in list">
          <a-menu-item :key="item.music_id" @click="onClickItem(item)">
            <a href="javascript:;">Track {{index + 1}}</a>
          </a-menu-item>
        </template>
      </template>
      <a-spin v-else :spinning="loading">
        <div style="height: 100px; width: 80px;"></div>
      </a-spin>
    </a-menu>
  </a-dropdown>
</template>

<script>
  import {Dropdown, Menu, Spin} from 'ant-design-vue';

  export default {
    data() {
      return {
        list: [],
        loading: false,
        isOpen: false,
      }
    },

    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      }
    },

    components: {
      ADropdown: Dropdown,
      AMenu: Menu,
      AMenuItem: Menu.Item,
      ASpin: Spin
    },

    methods: {
      getMusicVersion(music_id) {
        let params = {music_id};

        this.loading = true;

        this.$axios.Music_Info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.list = data.data ? data.data.filter(item => item.music_id !== this.item.music_id) : [];
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      // onVisibleChange(bool) {
      //   if (bool) {
      //     this.getMusicVersion(this.item.music_id || '');
      //   }
      // },

      onClickItem(item) {
        let {audition_url, origin_url} = item;

        let url = origin_url ? origin_url : audition_url;
        let {suplr_name} = this.item;

        // music_id 切换
        this.$store.commit('changeVideoInfo', Object.assign({}, this.item, {audition_url: url, suplr_name}));
      },

      onChangeVisible(visiable) {
        this.isOpen = visiable;

        if (visiable) {
          this.getMusicVersion(this.item.music_id || '');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .version-num {
    padding: 2px 0;

    .bottom-arrow {
      transition: all linear 0.2s;
      transform: rotate(0deg);
    }

    &.open {
      .bottom-arrow {
        transform: rotate(180deg);
      }
    }

    .bottom-arrow {
      font-size: 12px !important;
    }
  }
</style>
